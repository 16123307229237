<template>
  <v-app>
    
    <router-view/>
    <div class="MyAlertNotification" v-if="ShowAlert">
      <v-alert
      :color="Coloralert"
      dark
      :icon="IconAlert"
      border="left"
      prominent
      style="zoom:80%"
    >
      {{MsgAlert}}
    </v-alert>
    </div>
  </v-app>
</template>

<script>

import {version} from '../package'

export default {
  name: 'App',
  data: () => ({
    ShowAlert:false,
    Coloralert:'error',
    IconAlert:'mdi-vuetify',
    MsgAlert: 'Praesent congue erat at massa. Nullam vel sem. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Proin viverra, ligula sit amet ultrices semper, '
  }),
  created(){

    var dominio = 'controlpanel.ticket2pass.com'
    if (window.location.protocol == "http:") {
        if(window.location.hostname == dominio){
            if(window.location.pathname != "/"){
                window.location.replace("https://" + dominio + window.location.pathname)
            }else{
                window.location.replace("https://" + dominio)    
            }
        }
    }

    this.$root.versionX = version
  }
};
</script>

<style>
  .MyAlertNotification{
    position: absolute;
    top: calc(100vh - 64px);
    left: 0;
    width: 100vw;
    height: 50px;
    color: white;
    opacity: 0.7;
    z-index: 999999;
  }
  body{
    padding-right: 0px !important;
  }
</style>